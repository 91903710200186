body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  padding: 10px;
  margin:0px;
  border: 0;
}

header {
  padding: 0;
  margin: 0;
  height:80px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .title {
  flex: 2;
}

header .actions {
  flex: 1;
  justify-content: flex-end;
}

header .title h1 {
  margin:0px;
}

h3 {
  margin-bottom:10px;
}

.tools div {
  float: left;
}

.tools .button {
  width:150px;
}

.tools .button:active {
  width:141px;
  height:26px;
  margin:7px;
  padding: 4px;
  font-size: 12px;
}

.searchForm {
  background-color: #000000;
  padding:5px;
  margin-bottom: 20px;
}

.searchForm label {
  margin-top: 5px;
  color: #ffffff;
  font-weight: normal;
}

.search {
  flex-direction: row;
  display:flex;
}

.search > div {
  flex:1;
}

.search > div > label {
  margin-right: 5px;
}

button {
  font-size: 14px;
}

.buttons {
  display:flex;
  flex-direction: column;
}

form {
  display:flex;
  flex-direction: column;
}

.right {
  flex: 1;
  float:right;
  text-align: right;
}

.mediaItem {
  display: flex;
  height: 200px;
  margin-bottom: 10px;
  align-items: center;
}

.moderationImage {
  max-width:200px;
  max-height: 200px;
  margin-right: 20px;
}

.profileImage {
  max-width:120px;
  max-height: 200px;
  margin-right: 20px;
}

.input {
  border: solid #000 1px;
  width: 190px;
  height: 20px;
  padding: 5px;
}

.button {
  border: solid #000 1px;
  background-color: #000;
  width:202px;
  height:30px;
  color: #FFF;
  margin: 5px;
  padding: 5px;
  text-align:center;
  cursor: pointer;
  margin-left: 0px;
}

.button:active {
  width:186px;
  height:26px;
  margin:7px;
  padding: 4px;
  font-size: 12px;
}

.half {
  /*flex: 1;*/
}

.messaging {
  background-color: #ff0171;
}

.approve {
  background-color: green;
}

.reject {
  background-color: red;
}

.spinner {
  display:flex;
  flex: 1;
  align-content: center;
  justify-content: center;
}
